























import { Component, Prop } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import SectionComponent from '@/components/SectionComponent.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import UiPageWidget from '@/models/graphql/UiPageWidget';

@Component({
  components: {
    SectionComponent,
  },
})
export default class InformationCardListWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'InformationCardListWidgetStore';

  @Prop({ required: false, default: null })
  private readonly title!: string;

  @Prop({ required: false, default: null })
  private readonly subtitle!: string;

  @Prop({ required: false, default: () => [] })
  private readonly items!: UiPageWidget[];

  private get slideCardSize(): number {
    switch (this.breakpoint.value) {
      case 'xl':
        return 255;
      case 'lg':
      case 'md':
        return 330;
      case 'sm':
      case 'xs':
        return 290;
      default:
        return 255;
    }
  }

  created(): void {
    this.setDataConfig();
  }
}
